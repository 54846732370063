import PulseLoader from "react-spinners/PulseLoader";
import { useGoogleAuthMutation } from "./authApiSlice";
import { GoogleLogin } from "@react-oauth/google";
import { setCredentials } from "./authSlice";
import { useDispatch } from "react-redux";
import { Box, HStack, VStack } from "@chakra-ui/react";

const GoogleLoginComponent = () => {
  const dispatch = useDispatch();
  let content = (
    <VStack
      alignItems="center"
      alignSelf={"center"}
      alignContent={"center"}
      padding="10px 0px"
      width={"100%"}
      textAlign="center"
    >
      <GoogleLogin
        width={"100%"}
        align={"center"}
        onSuccess={(credentialResponse) => {
          logIn(credentialResponse);
        }}
        onError={() => {
          console.log("Login Failed");
        }}
      />
    </VStack>
  );
  const [logIn, { data: token, isLoading, isSuccess, isError, error }] =
    useGoogleAuthMutation();
  if (isLoading) {
    content = <PulseLoader color="green" />;
  }
  if (isSuccess) {
    console.log(`token:`);
    console.log(token);
    dispatch(
      setCredentials({
        accessToken: token.accessToken,
      }),
    );
  }
  if (isError) {
    console.log(error);
  }
  return content;
};
export default GoogleLoginComponent;
