import { createSlice } from "@reduxjs/toolkit";

export const refreshPlanedSmsSlice = createSlice({
  name: "refreshPlanedSms",
  initialState: {
    refreshPlanedSms: null,
  },
  reducers: {
    setRefreshPlanedSms: (state, action) => {
      // console.log(action.payload);
      state.refreshPlanedSms = action.payload;
    },
  },
});

export const { setRefreshPlanedSms } = refreshPlanedSmsSlice.actions;
export default refreshPlanedSmsSlice.reducer;
