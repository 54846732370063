import { Button } from "@chakra-ui/react";

const SendSmsComponent = ({ message, nrs, onOpen }) => {
  return (
    <>
      <Button
        isDisabled={nrs.length === 0}
        w={"100%"}
        onClick={onOpen}
        className="primaryBackground small"
        color={"#eeeeee"}
      >
        Wyślij
      </Button>
    </>
  );
};

export default SendSmsComponent;
