import { Button } from "@chakra-ui/react";
import { usePlanSmsMutation } from "./smsApiSlice";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setRefreshPlanedSms } from "./refreshPlanedSmsSlice";

const PlanSmsComponent = ({ nrs, gid, uid, message, smsDate, hour }) => {
  const dispatch = useDispatch();
  const refreshPlanedSms = useSelector(
    (state) => state.refreshPlanedSms.refreshPlanedSms
  );
  // const date = format(smsDate, "yyyy-MM-dd");
  console.log(`hour: ${hour}`);
  // const splitDate = date.split("-");
  // const day = splitDate[2];
  // const month = splitDate[1];
  // const year = splitDate[0];
  const [addPlan, { data: plan }] = usePlanSmsMutation();
  const handleClick = () => {
    const date = smsDate.setHours(hour, 0, 0);
    const dateString = format(
      smsDate.setHours(hour, 0, 0),
      "dd-MM-yyyy HH:mm:ss"
    );
    addPlan({
      gid,
      uid,
      nrs,
      message,
      smsDateFull: new Date(date),
      smsDate: dateString,
    });
  };
  useEffect(() => {
    if (plan) {
      const { ids, entities } = plan;
      dispatch(setRefreshPlanedSms(!refreshPlanedSms));
      const res = ids?.map((i) => entities[i]);
      console.log(res);
    }
  }, [plan]);
  return (
    <Button
      onClick={handleClick}
      isDisabled={nrs.length === 0}
      w={"100%"}
      className="greenBackground small"
      color={"white"}
    >
      Zaplanuj
    </Button>
  );
};

export default PlanSmsComponent;
