import {
  FormControl,
  Input,
  useDisclosure,
  useToast,
  Box,
  Button,
  Container,
  Select,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setVehicle } from "./selectedVehicleSlice";
import { useSelector } from "react-redux";
import { setUserObj } from "../users/selectedUserSlice";

const VehiclesSearchBox = ({ placeholder, data, mode }) => {
  const dispatch = useDispatch();
  const selectedVehicle = useSelector(
    (state) => state.selectedVehicle.selectedVehicle
  );
  const [overflow, setOverflow] = useState("hidden");
  const [filteredData, setFilteredData] = useState([]);
  const [isEditing, setIsEditing] = useState(true);
  const [border, setBorder] = useState("0px");
  const [background, setBackground] = useState("transparent");
  const [popHeader, setPopHeader] = useState(<></>);

  const searchData = (e) => {
    if (e.target.value.length >= 0) {
      setBorder("1px");
      const searchWord = e.target.value;
      const newFilter = data?.filter((d) => {
        return d.reg.toLowerCase().includes(searchWord.toLowerCase());
      });
      setOverflow("scroll");
      setFilteredData(newFilter);
      setIsEditing(true);
    } else {
      // console.log(`e.target.value2: ${e.target.value}`);
      setBorder("0px");
      setIsEditing(false);
      setFilteredData([]);
      setOverflow("hidden");
    }
  };
  const vehicleSelected = ({ vehicle }) => {
    // console.log(vehicle);
    dispatch(setVehicle(vehicle));
    setFilteredData([]);
    setBorder("0px");
  };
  const selectHandle = (d) => {
    console.log(d);
    vehicleSelected({ vehicle: d });
    if (mode == "top") {
      dispatch(setVehicle(d));
      dispatch(setUserObj(d.user));
    }
  };
  return (
    <>
      <Popover
        bg={background}
        isOpen={true}
        closeOnBlur={true}
        isLazy
        lazyBehavior="keepMounted"
      >
        <PopoverTrigger bg={background}>
          <Box bg={background}>
            <Input
              placeholder={placeholder}
              onChange={searchData}
              onFocus={(e) => {
                setBackground("#fafafa");
                setOverflow("hidden");
                searchData(e);
                setBorder("1px");
                setPopHeader(
                  <PopoverHeader>
                    <PopoverCloseButton />
                  </PopoverHeader>
                );
              }}
              onBlur={(e) => {
                // console.log(e.relatedTarget);
                // only re-focus if the user clicked on something
                // that was NOT an input element
                // if (
                //   e.relatedTarget === null ||
                //   !e.relatedTarget.id.includes("popover-content")
                // )
                if (e.relatedTarget === null) {
                  setOverflow("hidden");
                  setBackground("transparent");
                  setFilteredData([]);
                  setBorder("0px");
                  e.target.focus();
                  setFilteredData([]);
                  setBorder("0px");
                  setOverflow("hidden");
                } else if (e.relatedTarget.id.includes("chakra-modal")) {
                  setBackground("transparent");
                  setFilteredData([]);
                  setBorder("0px");
                  setOverflow("hidden");
                } else {
                }
              }}
            />
          </Box>
        </PopoverTrigger>
        <PopoverContent
          bg={background}
          border={border}
          borderColor="gray.200"
          boxShadow="none"
        >
          <PopoverBody bg={background} maxHeight="200px" overflowY={overflow}>
            {filteredData?.map((d) => {
              return (
                <p
                  key={d.id}
                  onClick={() => {
                    setOverflow("hidden");
                    setBackground("transparent");
                    setFilteredData([]);
                    setBorder("0px");
                    setPopHeader(<></>);
                    selectHandle(d);
                  }}
                >
                  {d.name} {d.reg}
                </p>
              );
            })}
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </>
  );
};

export default VehiclesSearchBox;
