import { Select, VStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setVehicle } from "./selectedVehicleSlice";
import { useSelector } from "react-redux";
import VehiclesSearchBox from "./VehiclesSearchBox";
import { useLazyGetVehiclesQuery } from "./vehiclesApiSlice";
import { Button, Spinner } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

const VehiclesListSelect = ({ mode }) => {
  const UserInfo = useAuth();
  const { garageId } = useParams();
  let vehiclesList;
  const [changeVehicle, setChangeVehicle] = useState(false);
  const [
    getVehicles,
    { data: vehicles, isLoading, isSuccess, isError, error },
  ] = useLazyGetVehiclesQuery();
  const useVehiclesList = async () => {
    // console.log(`garageId: ${garageId}, uid: ${UserInfo._id}`);
    await getVehicles({ gid: garageId, uid: UserInfo._id });
    setChangeVehicle(!changeVehicle);
  };
  if (isSuccess) {
    vehiclesList = vehicles.ids?.map((vid) => vehicles.entities[vid]);
  }
  return (
    <VStack spacing="25px">
      <Button
        bg={mode === "top" ? "blue.300" : "transparent"}
        colorScheme="teal"
        variant={mode == "top" ? "solid" : "link"}
        onClick={useVehiclesList}
      >
        {mode == "vehicles" ? (
          <u>lista istniejących pojazdów</u>
        ) : (
          "szukaj wg pojazdu:"
        )}
      </Button>
      {isLoading && <Spinner />}
      {isSuccess && (
        <VehiclesSearchBox
          mode={mode}
          placeholder="nr rej. pojazdu"
          data={vehiclesList}
        />
      )}
    </VStack>
  );
};

export default VehiclesListSelect;
