import { createSlice } from "@reduxjs/toolkit";

export const selectedItemDetailsSlice = createSlice({
  name: "selectedItemDetails",
  initialState: {
    selectedItemDetails: [],
  },
  reducers: {
    setSelectedItemDetails: (state, action) => {
      // return { ...state, stIds: [...state.stIds, action.payload] };
      return { selectedItemDetails: [action.payload] };
    },
  },
});

export const { setSelectedItemDetails } = selectedItemDetailsSlice.actions;
export default selectedItemDetailsSlice.reducer;
