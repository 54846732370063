import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { setGarage } from "./selectedGarageSlice";
import { Box, HStack, Button, VStack } from "@chakra-ui/react";
import AvaliableComponent from "./AvailableComponent";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import { setDate } from "../calendar/selectedDateSlice";
import { format, set } from "date-fns";
import { transform } from "framer-motion";
import useAuth from "../../hooks/useAuth";

const Garage = ({ garage }) => {
  const UserInfo = useAuth();
  const dispatch = useDispatch();
  let selectedDate = useSelector((state) => state.selectedDate.date);
  const date = new Date();
  const formattedDate = date.toLocaleDateString("en-GB");
  const navigate = useNavigate();

  const [paramsDay, setParamsDay] = useState();
  const [paramsMonth, setParamsMonth] = useState();
  const [paramsYear, setParamsYear] = useState();
  const [isAdmin, setIsAdmin] = useState(false);
  const [isEmployee, setIsEmployee] = useState(false);
  useEffect(() => {
    if (
      UserInfo?.roles?.isadmin?.filter((g) => g._id === garage._id).length > 0
    ) {
      setIsAdmin(true);
    }
    if (
      UserInfo?.roles?.isemployee?.filter((g) => g._id === garage._id).length >
      0
    ) {
      setIsEmployee(true);
    }
    console.log(`isEmloyee: ${isEmployee} isAdmin: ${isAdmin}`);
  }, [UserInfo, garage]);

  const handleEdit = () => {
    dispatch(setGarage(garage));
    navigate(`/dash/${garage._id}/calendar/${formattedDate}`);
  };
  let today = null;
  if (!selectedDate || selectedDate === null) {
    dispatch(setDate(formattedDate));
  }
  useEffect(() => {
    if (selectedDate) {
      const split = selectedDate?.split("/");
      setParamsDay(split[0]);
      setParamsMonth(split[1]);
      setParamsYear(split[2]);
    }
  }, [selectedDate]);
  today = new Date(`${paramsYear}-${paramsMonth}-${paramsDay}`);
  return (
    <>
      <Box className="garageCard">
        <VStack width={"100%"} alignItems={"center"}>
          <span width={"100%"} className="garageCardName">
            <p className="gray small">WarsztApp: </p>
            <b>{garage.name}</b>
          </span>
          <div></div>
          <HStack alignItems={"left"} w={"100%"}>
            <VStack alignItems={"left"} className="garageCardBody">
              <VStack>
                {(isAdmin || isEmployee) && (
                  <Button
                    border={"1px solid #efefef"}
                    bg={"transparent"}
                    size={"sm"}
                    w={"100%"}
                    color={"gray.500"}
                    loadingText="Loading"
                    spinnerPlacement="end"
                    padding={"18px 30px 20px 30px"}
                    marginTop={"30px"}
                    borderRadius={"4px"}
                    onClick={handleEdit}
                    leftIcon={<FontAwesomeIcon icon={faGear} />}
                  >
                    <span className="small bold">ZARZĄDZAJ</span>
                  </Button>
                )}
                <Button
                  size={"sm"}
                  border={"1px solid #efefef"}
                  bg={"transparent"}
                  color={"#77c23a"}
                  w={"100%"}
                  loadingText="Loading"
                  spinnerPlacement="end"
                  padding={"18px 30px 20px 30px"}
                  borderRadius={"4px"}
                  // onClick={handleEdit}
                  leftIcon={<FontAwesomeIcon icon={faLocationDot} />}
                >
                  <span className="small bold">POKAZ NA MAPIE</span>
                </Button>
              </VStack>
              <p className="gray small">adres: </p>{" "}
              <p className="small">
                {garage.street}
                {garage.nr}
              </p>
              <p className="gray small">telefon:</p>
              <span className="small">{garage.phones[0]}</span>
              {garage.www && (
                <p
                  className="weblink small"
                  onClick={() => window.open(garage.www, "_blank")}
                >
                  {garage.www}
                </p>
              )}
            </VStack>
            <VStack className="garageCardBody">
              <HStack className="">
                <Button
                  className="primaryColor"
                  onClick={() => {
                    const newDate = today.setDate(today.getDate() - 1);
                    dispatch(setDate(format(new Date(newDate), "dd/MM/yyyy")));
                  }}
                  bg={"transparent"}
                  fontSize={"1.5em"}
                  leftIcon={<FontAwesomeIcon icon={faChevronLeft} />}
                />{" "}
                <span className="gray small">
                  {paramsYear}-{paramsMonth}-{paramsDay}
                </span>
                <Button
                  className="primaryColor"
                  bg={"transparent"}
                  fontSize={"1.5em"}
                  onClick={() => {
                    const newDate = today.setDate(today.getDate() + 1);
                    dispatch(setDate(format(new Date(newDate), "dd/MM/yyyy")));
                  }}
                  leftIcon={<FontAwesomeIcon icon={faChevronRight} />}
                />
              </HStack>
              <HStack className="">
                <AvaliableComponent
                  day={paramsDay}
                  month={paramsMonth}
                  year={paramsYear}
                  garage={garage}
                  alignSelf={"flex-start"}
                />
              </HStack>
            </VStack>
          </HStack>
        </VStack>
      </Box>
    </>
  );
};

const memoizedGarage = memo(Garage);

export default memoizedGarage;
