import { Input, Box, Stack, HStack, Button } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AddIcon } from "@chakra-ui/icons";
import { useAddNewEmployeeMutation } from "../../components/settings/employeesApiSlice";
import SendEmail from "../mailer/SendEmail";

const NewStaffForm = () => {
  const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  const [emali, setEmail] = useState("");
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [validEmail, setValidEmail] = useState(false);
  const selectedGarage = useSelector((state) => state.selectedGarage.garage);

  const [addUser, { data: staff, isLoading, isSuccess, isError, error }] =
    useAddNewEmployeeMutation();
  const onEmailChange = (event) => {
    setEmail(event.target.value);
    setValidEmail(EMAIL_REGEX.test(event.target.value));
  };
  const onNameChange = (event) => {
    setName(event.target.value);
  };
  const addNewStaff = async () => {
    if (!name || !emali) {
      setMessage("pola nie mogą być puste");
    } else {
      if (validEmail) {
        addUser({
          mode: "staff",
          email: emali,
          name: name,
          gid: selectedGarage._id,
        });
      } else {
        setMessage("niepoprawny email");
      }
    }
  };
  useEffect(() => {
    if (staff) {
      if (staff.task === "activate") {
        // console.log("accout must be activated");
        setMessage(<SendEmail task="activate" body={staff} />);
      }
    }
  }, [staff]);
  return (
    <>
      {message}
      <HStack width={"100%"}>
        <Input
          onChange={onNameChange}
          borderRadius={"4px"}
          w={"100%"}
          background={"white"}
          size={"sm"}
          placeholder="Nazwa"
        />
        <Input
          onChange={onEmailChange}
          w={"100%"}
          borderRadius={"4px"}
          background={"white"}
          size={"sm"}
          placeholder="email"
        />
        <Button
          isLoading={isLoading}
          onClick={() => {
            addNewStaff();
          }}
          size="sm"
          colorScheme="cyan"
          color={"white"}
          minW={"100px"}
        >
          zapisz
        </Button>
      </HStack>
    </>
  );
};
export default NewStaffForm;
