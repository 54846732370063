import { apiSlice } from "../../app/api/apiSlice";
import { logOut, setCredentials } from "./authSlice";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: "/auth",
        method: "POST",
        body: { ...credentials },
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
        } catch (err) {}
      },
    }),
    tokenCheck: builder.mutation({
      query: ({ token }) => ({
        url: "/auth/tokencheck",
        method: "POST",
        body: { token },
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
        } catch (err) {}
      },
    }),
    resetPassword: builder.mutation({
      query: ({ token, password }) => ({
        url: "/auth/reset",
        method: "POST",
        body: { token, password },
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
        } catch (err) {}
      },
    }),
    sendLogout: builder.mutation({
      query: () => ({
        url: "/auth/logout",
        method: "POST",
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(logOut());
          setTimeout(() => {
            dispatch(apiSlice.util.resetApiState());
          }, 1000);
        } catch (err) {
          console.log(err);
        }
      },
    }),
    googleAuth: builder.mutation({
      query: ({ credential }) => ({
        url: "/auth/google/success",
        method: "POST",
        body: { token: credential },
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          setTimeout(() => {
            dispatch(apiSlice.util.resetApiState());
          }, 1000);
        } catch (err) {
          console.log(err);
        }
      },
    }),
    refresh: builder.mutation({
      query: () => ({
        url: "/auth/refresh",
        method: "GET",
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          const { accessToken } = data;
          dispatch(setCredentials({ accessToken }));
        } catch (err) {
          console.log(err);
        }
      },
    }),
  }),
});

export const {
  useGoogleAuthMutation,
  useLoginMutation,
  useTokenCheckMutation,
  useResetPasswordMutation,
  useSendLogoutMutation,
  useRefreshMutation,
} = authApiSlice;
