import { createSlice } from "@reduxjs/toolkit";

export const selectedNrsSlice = createSlice({
  name: "selectedNrs",
  initialState: {
    nrs: [],
  },

  reducers: {
    setNrs: (state, action) => {
      state.nrs = action.payload;
    },
    addNrs: (state, action) => {
      return { ...state, nrs: [...state.nrs, action.payload] };
    },
    removeNrs: (state, action) => {
      // console.log(action.payload);
      // // state.stIds.filter((event) => state.stId._id !== action.payload);
      return {
        ...state,
        nrs: state.nrs.filter((event) => event._id !== action.payload),
      };
    },
  },
});
export const { setNrs, addNrs, removeNrs } = selectedNrsSlice.actions;
export default selectedNrsSlice.reducer;
