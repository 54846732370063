import {
  Box,
  Button,
  HStack,
  InputGroup,
  Select,
  Switch,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import MyDatePicker from "../calendar/DatePicker";
import { useEffect, useRef, useState } from "react";
import { set } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import NrComponent from "./NrComponent";
import NrBadge from "./NrBadge";
import { setNrs } from "./selectedNrsSlice";
import { useSendSmsMutation } from "./smsApiSlice";
import DatePicker from "react-datepicker";
import SendSmsComponent from "./SendSmsComponent";
import PlanSmsComponent from "./PlanSmsComponent";

const SmsComponent = ({ uid }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef();
  const dispatch = useDispatch();
  const [plan, setPlan] = useState(false);
  const [message, setMessage] = useState("test");
  const [loading, setLoading] = useState(false);
  const [smsDate, setSmsDate] = useState(new Date());
  const [hour, setHour] = useState(9);
  const selectedGarage = useSelector((state) => state.selectedGarage.garage);
  const nrs = useSelector((state) => state.selectedNrs.nrs);

  const serviceModalMode = useSelector(
    (state) => state.serviceModalMode.serviceModalMode
  );
  const [sendSms, { data: sms, isLoading, isSuccess, isError, error }] =
    useSendSmsMutation();
  useEffect(() => {
    if (isSuccess) {
      // dispatch(setNrs([]));
    }
  }, [isSuccess]);
  const switchHandler = () => {
    setPlan(!plan);
  };
  const sendHandler = async () => {
    setLoading(true);
    sendSms({ nrs: nrs, gid: selectedGarage._id, message });
  };
  useEffect(() => {
    if (sms) {
      console.log(sms);
      setLoading(false);
      onClose();
    }
  }, [sms]);
  const handleHour = (e) => {
    setHour(e.target.value);
  };
  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Wyślij wiadomość
            </AlertDialogHeader>

            <AlertDialogBody>
              <p>
                {" "}
                Czy wysłać <span className={"bold"}> {nrs?.length} </span>{" "}
                wiadomości?
              </p>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                onClick={() => {
                  setLoading(false);
                  onClose();
                }}
              >
                anuluj
              </Button>
              <Button
                isLoading={loading}
                colorScheme="red"
                onClick={sendHandler}
                ml={3}
              >
                Wyślij
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <Box width={"100%"} h={"100%"} verticalAlign={"top"}>
        <InputGroup>
          <VStack w={"100%"}>
            {plan ? (
              <PlanSmsComponent
                smsDate={smsDate}
                gid={selectedGarage._id}
                uid={uid}
                message={message}
                nrs={nrs}
                hour={parseInt(hour)}
                onOpen={onOpen}
              />
            ) : (
              <SendSmsComponent onOpen={onOpen} message={message} nrs={nrs} />
            )}
            <Textarea onChange={(e) => setMessage(e.target.value)}></Textarea>
          </VStack>
        </InputGroup>
        <VStack>
          <HStack w={"100%"} marginTop={2}>
            <HStack w={"100%"}>
              <Switch
                className="smsswitch"
                label={"zaplanuj"}
                onChange={switchHandler}
                size="sm"
              />
              <p> zaplanuj</p>
            </HStack>
            {plan && (
              <HStack w={"100%"}>
                <Box w={"70%"} p={1} borderRadius={"5px"}>
                  <DatePicker
                    wrapperClassName="datepicker"
                    alignItems={"center"}
                    width="120px"
                    textAlign={"center"}
                    onChange={(date) => setSmsDate(date)}
                    selected={smsDate}
                  />
                </Box>
                <Select onChange={handleHour} w={"50%"} size={"sm"}>
                  <option>8:00</option>
                  <option>9:00</option>
                  <option>10:00</option>
                  <option>11:00</option>
                  <option>12:00</option>
                  <option>13:00</option>
                  <option>14:00</option>
                  <option>15:00</option>
                  <option>16:00</option>
                  <option>17:00</option>
                  <option>18:00</option>
                  <option>19:00</option>
                  <option>20:00</option>
                </Select>
              </HStack>
            )}
          </HStack>
          <>
            {nrs?.length === 0 ? (
              "Nie dodano numerów..."
            ) : (
              <>
                {!serviceModalMode && (
                  <>
                    <Box>dodano: {nrs?.length}</Box>
                    <Button
                      color={"white"}
                      w={"100%"}
                      bg={"red.300"}
                      onClick={() => {
                        dispatch(setNrs([]));
                      }}
                    >
                      Usuń wszystkie
                    </Button>
                  </>
                )}
              </>
            )}
          </>
        </VStack>
      </Box>
    </>
  );
};

export default SmsComponent;
