import { Box, Button, Input, InputGroup, Stack } from "@chakra-ui/react";
import { Skeleton, SkeletonCircle, SkeletonText } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import NrComponent from "./NrComponent";
import { useEffect, useState } from "react";
import { useGetUsersQuery, useLazyGetUsersQuery } from "../users/usersApiSlice";
import { useParams } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { addNrs, setNrs } from "./selectedNrsSlice";

const NumbersList = () => {
  const dispatch = useDispatch();
  const { garageId } = useParams();
  const UserInfo = useAuth();
  const [filteredList, setFilteredList] = useState([]);
  const [content, setContent] = useState([]);
  const [getUsers, { data: users, isLoading, isSuccess }] =
    useLazyGetUsersQuery();
  const [list, setList] = useState([]);

  const addAll = () => {
    if (users) {
      const { ids, entities } = users;
      dispatch(setNrs([]));
      ids?.map((id) => dispatch(addNrs(entities[id])));
    }
  };
  const getUsersList = async () => {
    await getUsers({
      gid: garageId,
      uid: UserInfo._id,
    });
  };
  useEffect(() => {
    getUsersList();
  }, []);
  useEffect(() => {
    if (users) {
      const { ids, entities } = users;
      setFilteredList(ids?.map((id) => entities[id]));
      setList(ids?.map((id) => entities[id]));
    }
  }, [users]);
  useEffect(() => {
    if (filteredList) {
      setContent(
        filteredList?.map((id) => <NrComponent key={id?._id} user={id} />)
      );
    }
  }, [filteredList]);
  const handleHange = (e) => {
    const value = e.target.value;
    const filtered = list?.filter((user) =>
      user?.username?.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredList(filtered);
  };
  return (
    <>
      <Button
        isLoading={isLoading}
        onClick={addAll}
        bg={"blue.300"}
        color={"#ffffff"}
        marginBottom={2}
        size={"md"}
        w={"100%"}
      >
        Dodaj wszystkie
      </Button>
      <InputGroup>
        <Input
          placeholder="Szukaj..."
          onChange={handleHange}
          border={"1px solid #eee"}
          borderRadius={"full"}
        />
      </InputGroup>
      <Box overflowY={"scroll"} height={"80vh"} paddingRight={10}>
        {content?.length === 0 ? (
          <Stack>
            <Skeleton height="20px" />
            <Skeleton height="20px" />
            <Skeleton height="20px" />
            <Skeleton height="20px" />
            <Skeleton height="20px" />
            <Skeleton height="20px" />
            <Skeleton height="20px" />
            <Skeleton height="20px" />
            <Skeleton height="20px" />
            <Skeleton height="20px" />
            <Skeleton height="20px" />
            <Skeleton height="20px" />
            <Skeleton height="20px" />
            <Skeleton height="20px" />
            <Skeleton height="20px" />
            <Skeleton height="20px" />
            <Skeleton height="20px" />
            <Skeleton height="20px" />
            <Skeleton height="20px" />
            <Skeleton height="20px" />
            <Skeleton height="20px" />
            <Skeleton height="20px" />
            <Skeleton height="20px" />
            <Skeleton height="20px" />
            <Skeleton height="20px" />
            <Skeleton height="20px" />
            <Skeleton height="20px" />
            <Skeleton height="20px" />
            <Skeleton height="20px" />
            <Skeleton height="20px" />
            <Skeleton height="20px" />
            <Skeleton height="20px" />
            <Skeleton height="20px" />
          </Stack>
        ) : (
          <>{content}</>
        )}
      </Box>
    </>
  );
};

export default NumbersList;
