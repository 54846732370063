import { useEffect, useState } from "react";
import { useGetSmsPlanByGidMutation } from "./smsApiSlice";
import { Box, CircularProgress } from "@chakra-ui/react";
import PlanComponent from "./PlanComponent";
import { useSelector } from "react-redux";

const PlanedList = ({ uid, gid }) => {
  console.log(`uid`);
  console.log(uid);
  const refreshPlanedSms = useSelector(
    (state) => state.refreshPlanedSms.refreshPlanedSms
  );
  const [content, setContent] = useState(
    "Nie masz zaplanowanych wiadomości ..."
  );
  const [getSmsPlan, { data: plan, isSuccess, isError, error }] =
    useGetSmsPlanByGidMutation();
  if (isError) {
    console.log(error);
  }
  useEffect(() => {
    if (uid) {
      getSmsPlan({ uid, gid });
    }
  }, [uid]);
  useEffect(() => {
    getSmsPlan({ uid, gid });
  }, [refreshPlanedSms]);
  useEffect(() => {
    if (plan) {
      const { ids, entities } = plan;
      setContent(
        ids?.map((i) => <PlanComponent uid={uid} gid={gid} p={entities[i]} />)
      );
      // console.log(`plan`);
      // console.log(plan);
      // console.log(`plan`);
    }
  }, [plan]);
  return (
    <>
      <Box
        color={"blue.300"}
        marginTop={2}
        marginBottom={15}
        h={"25px"}
        w={"100%"}
        align={"center"}
      >
        Planowane
      </Box>
      {plan ? (
        content
      ) : (
        <Box h={"100%"} w={"100%"} textAlign={"center"}>
          <CircularProgress size={"30px"} isIndeterminate color="blue.300" />
        </Box>
      )}
    </>
  );
};

export default PlanedList;
