import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Box,
  Spinner,
  Button,
} from "@chakra-ui/react";
import { setIsAppointmentModalOpen } from "./isAppointmentModalOpenSlice";
import { useEffect, useState } from "react";
import useAuth from "../../../hooks/useAuth";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import SendEmail from "../../mailer/SendEmail";
import selectedDateSlice from "../selectedDateSlice";

const AppointmentModal = ({ children }) => {
  const garage = useSelector((state) => state.selectedGarage.garage);
  const selectedMinute = useSelector((state) => state.selectedMinute.minute);
  const selectedHour = useSelector((state) => state.selectedHour.hour);

  const dispatch = useDispatch();
  const selectedDate = useSelector((state) => state.selectedDate.date);
  const [status, setStatus] = useState("pending");
  const [message, setMessage] = useState("");

  const UserInfo = useAuth();
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: false });
  const isAppointmentModalOpen = useSelector(
    (state) => state.isAppointmentModalOpen.isAppointmentModalOpen,
  );
  useEffect(() => {
    setMessage(
      <>
        Czy wysłać prośbę o wizytę w:
        <p>
          <b>{garage.name}</b>
        </p>
        <p>w dniu:</p>
        <p>
          <b>{selectedDate} </b>o godzinie:{" "}
          <b>
            {" "}
            {selectedHour} : {selectedMinute}
          </b>
          ?
        </p>
      </>,
    );
    console.log(isAppointmentModalOpen);
    if (isAppointmentModalOpen == true) {
      // console.log("click1");
      onOpen();
    } else {
      // console.log("click2");
      onClose();
    }
  }, [isAppointmentModalOpen]);
  const closeModal = () => {
    dispatch(setIsAppointmentModalOpen(false));
    setStatus("pending");
  };
  const send = async () => {
    const body = {
      garage: garage,
      email: UserInfo.email,
      date: selectedDate,
      hour: selectedHour,
      minute: selectedMinute,
      user: UserInfo._id,
      notes: "",
      author: UserInfo._id,
      authorname: UserInfo.username,
    };
    setMessage(<Spinner />);
    setMessage(<SendEmail task="new" body={body} />);

    setStatus("sent");
  };
  const content = (
    <>
      <span onClick={onOpen}>{children}</span>
      <Modal isOpen={isOpen} onClose={closeModal}>
        <ModalOverlay />
        <ModalContent width="400px">
          <ModalHeader></ModalHeader>
          <ModalCloseButton color={"gray.500"} size={"lg"} />
          <ModalBody>
            <Box
              borderRadius="lg"
              borderWidth="0px"
              padding="10px 10px 0 10px"
              color="#555555"
              margin="20px 0px 30px 0px"
              alignContent={"center"}
            >
              <p paddingTop={"20px"} className="small" align="center">
                {" "}
                {message}
              </p>
              {status === "pending" && (
                <Button
                  marginTop="30px"
                  width="100%"
                  colorScheme="cyan"
                  color="white"
                  // isLoading={isLoading}
                  onClick={() => send({ email: "", body: "" })}
                >
                  umów
                </Button>
              )}
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
  return content;
};
export default AppointmentModal;
