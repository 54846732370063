import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";
import { apiSlice } from "../app/api/apiSlice";

const preferencesAdapter = createEntityAdapter({});
const initialState = preferencesAdapter.getInitialState();

const preferencesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPreferences: builder.query({
      query: ({ gid }) => ({
        url: `/preferences/${gid}`,
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
      transformResponse: (responseData) => {
        const loadPreferences = responseData.map((preferences) => {
          preferences = preferences;
          return preferences;
        });
        return preferencesAdapter.setAll(initialState, loadPreferences);
      },
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "Preferences", id: "LIST" },
            ...result.ids.map((id) => ({ type: "Preferences", id })),
          ];
        } else return [{ type: "Preferences", id: "LIST" }];
      },
    }),
    getAllPreferences: builder.mutation({
      query: ({ gid }) => ({
        url: `/preferences/${gid}`,
        method: "GET",
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Garage", id: arg.id }],
    }),
  }),
});

export const { useGetPreferencesQuery, useGetAllPreferencesMutation } =
  preferencesApiSlice;

export const selectPreferencesResult =
  preferencesApiSlice.endpoints.getPreferences.select();

const selectPreferencesData = createSelector(
  selectPreferencesResult,
  (useResult) => useResult.data
);

export const { selectAll: selectAllPreferences } =
  preferencesAdapter.getSelectors(
    (state) => selectPreferencesData(state) ?? initialState
  );
