import { Box, Button, HStack, VStack } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { format } from "date-fns";
import { useDelPlanMutation } from "./smsApiSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { setRefreshPlanedSms } from "./refreshPlanedSmsSlice";

const PlanComponent = ({ p, uid, gid }) => {
  const dispatch = useDispatch();
  const refreshPlanedSms = useSelector(
    (state) => state.refreshPlanedSms.refreshPlanedSms
  );
  const date = format(p.date, "yyyy-MM-dd - H:mm");
  const [delPlan, { data: del }] = useDelPlanMutation();
  const handleDel = () => {
    const pid = p._id;
    delPlan({ messageId: p.messageId, uid, gid, pid });
  };
  useEffect(() => {
    if (del) {
      dispatch(setRefreshPlanedSms(!refreshPlanedSms));
    }
  }, [del]);
  return (
    <Box
      className="card2"
      bg={"gray.100"}
      borderRadius={4}
      p={0}
      m={4}
      border={"1px solid"}
      borderColor={"gray.100"}
    >
      <HStack w={"100%"}>
        <Box>
          <Button onClick={handleDel} m={2} size={"sm"} bg={"#fff"}>
            <FontAwesomeIcon color="red" size="sm" icon={faXmark} />
          </Button>
        </Box>
        <VStack align={"start"} w="100%" padding={"5px 10px"}>
          <HStack className="small gray">
            <p>data: </p>
            <p>
              <b>{date}</b>
            </p>
          </HStack>
          <HStack className="small gray">
            <p>liczba nrów: </p>
            <p>
              <b>{p.users.length}</b>
            </p>
          </HStack>
        </VStack>
        <Box>
          <Button m={2} size={"sm"} bg={"#fff"} color={"blue.300"}>
            <FontAwesomeIcon size="sm" icon={faMagnifyingGlass} />
          </Button>
        </Box>
      </HStack>
    </Box>
  );
};

export default PlanComponent;
