import { Button } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { useDeleteEmployeeMutation } from "./employeesApiSlice";

const EmployeeDeleteComponent = ({ uid, gid }) => {
  const [deleteEmployee, { isLoading, isSuccess, isError, error }] =
    useDeleteEmployeeMutation();
  const del = async () => {
    await deleteEmployee({ uid, gid });
  };
  const content = (
    <>
      <Button
        onClick={() => {
          del();
        }}
        isLoading={isLoading}
        bg={"transparent"}
      >
        <FontAwesomeIcon color="red" size="sm" icon={faXmark} />
      </Button>
    </>
  );
  return content;
};
export default EmployeeDeleteComponent;
