import { useEffect, useState } from "react";
import { useTokenCheckMutation } from "../../auth/authApiSlice";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { Box } from "@chakra-ui/react";

const TokenCheck = ({ token }) => {
  const navigate = useNavigate();
  const [redirect, setRedirect] = useState(false);
  const [tokenCheck, { data: response, isLoading, isSuccess, isError, error }] =
    useTokenCheckMutation();
  useEffect(() => {
    tokenCheck({ token });
  }, [token]);
  useEffect(() => {
    if (redirect === true) {
      // navigate("/");
    }
  }, [redirect]);

  // useEffect(() => {
  //   content = <div>{response?.message}</div>
  //   console.log("resp")
  //   console.log(response)
  //   console.log("resp")
  // }, [response]);
  let content = <Box color={"gray.500"}>Weryfikacja tokena...</Box>;
  if (isSuccess) {
    content = <Box color={"blue.300"}>{response?.message}</Box>
    console.log("resp")
    console.log(response)
    console.log("resp")
  }
  if (isError) {
    content = <Box color={"red"}>{error?.data?.message}</Box>
    console.log("resp")
    console.log(error)
    console.log("resp")
  }
  return content;
};
export default TokenCheck;
