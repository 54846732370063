import useTitle from "../../hooks/useTitle";
import PulseLoader from "react-spinners/PulseLoader";
import User from "../../features/users/User";
import { useEffect, useState } from "react";
import { AddIcon } from "@chakra-ui/icons";
import NewStaffForm from "../../features/users/NewStaffForm";
import {
  Button,
  Collapse,
  HStack,
  Input,
  Table,
  Td,
  Th,
  Thead,
  Tr,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { useGetEmployeesQuery } from "./employeesApiSlice";
import EmployeeDeleteComponent from "./EmployeeDeleteComponent";

const StaffList = ({ gid, uid }) => {
  const [show, setShow] = useState(false);

  const {
    data: employees,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetEmployeesQuery({ gid: gid, uid: uid }, "employeesList", {
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });
  let tableContent = "z";
  useEffect(() => {}, [employees]);
  useEffect(() => {
    console.log(show);
  }, [show]);
  if (isLoading) tableContent = <PulseLoader color={"#eee"} />;

  if (isError) {
    tableContent = <p className="errmsg">{error?.data?.message}</p>;
  }

  if (isSuccess) {
    const { ids, entities } = employees;

    tableContent =
      ids?.length &&
      ids.map((userId) => (
        <>
          <Tr>
            <Td>
              <EmployeeDeleteComponent uid={userId} gid={gid} />
            </Td>
            <Td className="proper">
              {entities[userId].username}
              <p className="small gray">
                <u>{entities[userId].email}</u>
              </p>
            </Td>
            <Td className="proper">{entities[userId].roles}</Td>
            <Td className="proper"></Td>
          </Tr>
        </>
      ));
  }

  return (
    <>
      <VStack>
        <Collapse in={show} animateOpacity>
          <NewStaffForm />
        </Collapse>
        <Table className="table table--users">
          <Thead className="table__thead">
            <Tr>
              <Th>
                {show === false ? (
                  <Button
                    onClick={() => {
                      setShow(!show);
                    }}
                    size="sm"
                    colorScheme="cyan"
                  >
                    <AddIcon marginRight="10px" w={3} h={3} color="#fff" />
                    <FontAwesomeIcon
                      color="#fff"
                      marginLeft="5px"
                      icon={faUser}
                    />
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      setShow(!show);
                    }}
                    size="sm"
                  >
                    <HStack>
                      <FontAwesomeIcon
                        marginRight="10px"
                        size="sm"
                        icon={faXmark}
                      />
                      <p marginLeft="5px" className="small gray">
                        anuluj
                      </p>
                    </HStack>
                  </Button>
                )}
              </Th>
              <Th scope="col" className="table__th user__username">
                nazwa/email
              </Th>
              <Th scope="col" className="table__th user__roles">
                rola
              </Th>
              <Th scope="col" className="table__th user__edit"></Th>
            </Tr>
          </Thead>
          <tbody>{tableContent}</tbody>
        </Table>
      </VStack>
    </>
  );
};
export default StaffList;
