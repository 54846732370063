const daily = [
  {
    id: 7,
    station: [
      {
        id: "1",
        parts: [
          {
            id: 0,
            status: false,
          },
          {
            id: 30,
            status: false,
          },
        ],
      },
    ],
  },
  {
    id: 8,
    station: [
      {
        id: "1",
        parts: [
          {
            id: 0,
            status: false,
          },
          {
            id: 30,
            status: false,
          },
        ],
      },
    ],
  },
  {
    id: 9,
    station: [
      {
        id: "1",
        parts: [
          {
            id: 0,
            status: false,
          },
          {
            id: 30,
            status: false,
          },
        ],
      },
    ],
  },
  {
    id: 10,
    station: [
      {
        id: "1",
        parts: [
          {
            id: 0,
            status: false,
          },
          {
            id: 30,
            status: false,
          },
        ],
      },
    ],
  },
  {
    id: 11,
    station: [
      {
        id: "1",
        parts: [
          {
            id: 0,
            status: false,
          },
          {
            id: 30,
            status: false,
          },
        ],
      },
    ],
  },
  {
    id: 12,
    station: [
      {
        id: "1",
        parts: [
          {
            id: 0,
            status: false,
          },
          {
            id: 30,
            status: false,
          },
        ],
      },
    ],
  },
  {
    id: 13,
    station: [
      {
        id: "1",
        parts: [
          {
            id: 0,
            status: false,
          },
          {
            id: 30,
            status: false,
          },
        ],
      },
    ],
  },
  {
    id: 14,
    station: [
      {
        id: "1",
        parts: [
          {
            id: 0,
            status: false,
          },
          {
            id: 30,
            status: false,
          },
        ],
      },
    ],
  },
  {
    id: 15,
    station: [
      {
        id: "1",
        parts: [
          {
            id: 0,
            status: false,
          },
          {
            id: 30,
            status: false,
          },
        ],
      },
    ],
  },
  {
    id: 16,
    station: [
      {
        id: "1",
        parts: [
          {
            id: 0,
            status: false,
          },
          {
            id: 30,
            status: false,
          },
        ],
      },
    ],
  },
  {
    id: 17,
    station: [
      {
        id: "1",
        parts: [
          {
            id: 0,
            status: false,
          },
          {
            id: 30,
            status: false,
          },
        ],
      },
    ],
  },
  {
    id: 18,
    station: [
      {
        id: "1",
        parts: [
          {
            id: 0,
            status: false,
          },
          {
            id: 30,
            status: false,
          },
        ],
      },
    ],
  },
];
export default daily;
