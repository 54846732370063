import { Outlet, useParams } from "react-router-dom";
import DashHeader from "./DashHeader";
import DashFooter from "./DashFooter";
import { VStack, Container, Box } from "@chakra-ui/react";
import useAuth from "../hooks/useAuth";
import { useDispatch, useSelector } from "react-redux";
import { setPreferences } from "../preferences/preferencesSlice";
import { useEffect } from "react";
import { useGetAllPreferencesMutation } from "../preferences/preferencesApiSlice";

const DashLayout = () => {
  const { garageId } = useParams();
  const UserInfo = useAuth();
  const dispatch = useDispatch();

  const [getPrefs, { data: preferences, isLoading }] =
    useGetAllPreferencesMutation();
  // const preferences = useSelector((state) => state.preferences);
  useEffect(() => {
    getPrefs({ gid: garageId });
  }, [garageId]);
  useEffect(() => {
    if (preferences) {
      console.log("preferences:");
      console.log(preferences);
      dispatch(
        setPreferences({ target: "ItemTypes", value: preferences.types })
      );
    }
  }, [preferences]);
  let dashContent = <></>;
  // if (UserInfo?.roles?.isadmin?.filter((g) => g._id === garageId).length > 0) {
  dashContent = (
    <VStack w="100%">
      <DashHeader />
      <Box m={0} p={4} w="100%">
        <Box
          bg="#fff"
          border="1px solid #eee"
          borderRadius="10px"
          m={0}
          padding="20px"
          w="100%"
        >
          <Outlet w="100%" />
        </Box>
      </Box>
      {/* <DashFooter /> */}
    </VStack>
  );
  // }
  return dashContent;
};
export default DashLayout;
