import { useParams } from "react-router-dom";
import { useResetPasswordMutation } from "../../auth/authApiSlice";
import { Box, Input, Button, Spinner } from "@chakra-ui/react";
import { useState } from "react";
import TokenCheck from "./TokenCheckComponent";

const Reset = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [resetPassword, { isLoading, isSuccess, isError, error }] =
    useResetPasswordMutation();
  let content = (
    <Box border={"1px solid #efefef"} borderRadius={"5"} p={"15px"}><p className="small gray">hasło</p>
      <Box>
        {message}
        <Input
          marginBottom={5} name="password" onChange={(e) => setPassword(e.target.value)} />
        <p className="small gray">powtórz hasło</p>
        <Input
          name="confirmPassword"
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        <Button w={"100%"} bg={"blue.300"} color={"white"}
          marginTop={5} onClick={() => reset()}>Resetuj</Button>
      </Box>
    </Box >
  );
  const { token } = useParams();
  const reset = () => {
    if (password === confirmPassword) {
      resetPassword({ token, password });
    } else {
      setMessage("hasła nie sa takie same");
    }
  };
  if (isLoading) {
    content = <Spinner />;
  }
  if (isSuccess) {
    content = <div>hasło zostało zmienione</div>;
  }
  if (isError) {
    if (error.data.message) {
      content = <div>{error.data.message}</div>;
    } else {
      content = <div>coś poszło nie tak...</div>;
    }
  }
  return (
    <>
      {" "}
      <TokenCheck token={token} /> {content}
    </>
  );
};

export default Reset;
