import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";

const smsAdapter = createEntityAdapter({});
const initialState = smsAdapter.getInitialState();

export const smsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    planSms: builder.mutation({
      query: ({ gid, uid, nrs, message, smsDate, smsDateFull }) => ({
        url: `/sms/addplan/${gid}`,
        method: "POST",
        body: { uid, nrs, message, smsDate, smsDateFull },
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
      transformResponse: (responseData) => {
        // const loadSms = responseData.map((sms) => {
        //   sms.id = sms._id;
        //   // console.log(sms);
        //   return responseData;
        // });
        return smsAdapter.setAll(initialState, responseData);
      },
    }),
    delPlan: builder.mutation({
      query: ({ pid, gid, uid, messageId }) => ({
        url: `/sms/delplan/${pid}`,
        method: "POST",
        body: { gid, uid, messageId },
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
      transformResponse: (responseData) => {
        // const loadSms = responseData.map((sms) => {
        //   sms.id = sms._id;
        //   // console.log(sms);
        //   return responseData;
        // });
        return smsAdapter.setAll(initialState, responseData);
      },
    }),
    sendSms: builder.mutation({
      query: ({ gid, uid, nrs, message }) => ({
        url: `/sms/selected/${gid}`,
        method: "POST",
        body: { uid, nrs, message },
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
      transformResponse: (responseData) => {
        return smsAdapter.setAll(initialState, responseData);
      },
    }),

    getSmsPlanByUid: builder.mutation({
      query: ({ uid, gid, nrs, message }) => ({
        url: `/sms/plan/${gid}`,
        method: "POST",
        body: { uid, nrs, message },
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
      transformResponse: (responseData) => {
        const loadSms = responseData.map((sms) => {
          sms.id = sms._id;
          // console.log(sms);
          return sms;
        });
        return smsAdapter.setAll(initialState, loadSms);
      },
    }),
    getSmsPlanByGid: builder.mutation({
      query: ({ uid, gid, nrs, message }) => ({
        url: `/sms/plans/${gid}`,
        method: "POST",
        body: { uid },
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
      transformResponse: (responseData) => {
        const loadSms = responseData.map((sms) => {
          sms.id = sms._id;
          // console.log(sms);
          return sms;
        });
        return smsAdapter.setAll(initialState, loadSms);
      },
    }),
  }),
});
export const {
  useDelPlanMutation,
  usePlanSmsMutation,
  useSendSmsMutation,
  useGetSmsPlanByUidMutation,
  useGetSmsPlanByGidMutation,
} = smsApiSlice;
