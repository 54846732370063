import { Box, Button, HStack, VStack } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { addNrs } from "./selectedNrsSlice";
import { removeNrs } from "./selectedNrsSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";

const NrComponent = ({ user }) => {
  const [isIn, setIsIn] = useState(false);
  const selectedNrs = useSelector((state) => state.selectedNrs.nrs);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const addNr = () => {
    setLoading(true);
    dispatch(addNrs(user));
  };

  const remove = () => {
    setLoading(true);
    dispatch(removeNrs(user._id));
  };
  useEffect(() => {
    setLoading(false);
    // if(selectedNrs.includes(user)){
    //   setLoading(true)
    // }
  }, [selectedNrs]);
  return (
    <>
      <Box
        className="hand small nrbackground"
        onClick={() => {
          setLoading(true);
          !selectedNrs.includes(user) ? addNr() : remove();
        }}
        w={"100%"}
        bg={selectedNrs.includes(user) ? "blue.100" : "gray.100"}
        borderRadius={5}
        m={2}
        p={2}
      >
        <HStack>
          <VStack w={"100%"} align={"start"}>
            <p>{user.phone}</p>
            <p className="small gray">{user.username}</p>
          </VStack>
          {!selectedNrs.includes(user) ? (
            <Button
              isLoading={loading}
              color={"blue.300"}
              size={"sm"}
              bg={"#ffffff"}
            >
              +
            </Button>
          ) : (
            <Button
              isLoading={loading}
              size={"sm"}
              bg={"#ffffff"}
              color={"red"}
            >
              <FontAwesomeIcon color="red" icon={faXmark} />
            </Button>
          )}
        </HStack>
      </Box>
    </>
  );
};

export default NrComponent;
