import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Box,
  Spinner,
  Button,
  VStack,
  FormControl,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import SendEmail from "../mailer/SendEmail";
import useAuth from "../../hooks/useAuth";

const ResetPasswordComponent = ({ mode }) => {
  console.log(`ResetPasswordComponent: ${mode}`);
  const UserInfo = useAuth();
  let content = <></>;
  const dispatch = useDispatch();
  const [status, setStatus] = useState("pending");
  const [message, setMessage] = useState("");
  const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  const [usersEmail, setUsersEmail] = useState("");
  const [validEmail, setValidEmail] = useState(false);

  const onEmailChange = (event) => {
    setUsersEmail(event.target.value);
    setValidEmail(EMAIL_REGEX.test(event.target.value));
  };
  const form = (
    <>
      <FormControl id="emal" isRequired>
        <FormLabel>Twój email</FormLabel>
        <Input
          className="form__input"
          type="email"
          id="email"
          onChange={onEmailChange}
          autoComplete="off"
          required
        />
      </FormControl>
    </>
  );

  useEffect(() => {
    setMessage(`Czy zresetować hasło?`);
  }, []);
  const garage = useSelector((state) => state.selectedGarage.garage);
  const send = async () => {
    let body = {};
    if (mode !== "modal") {
      body = {
        task: "resetPassword",
        garage: garage,
        email: UserInfo.email,
        user: UserInfo,
        notes: "",
        author: UserInfo._id,
        authorname: UserInfo.username,
      };
    } else {
      body = {
        task: "resetPassword",
        garage: null,
        email: usersEmail,
        user: {},
        notes: "",
        author: "",
        authorname: usersEmail,
      };
    }
    console.log(`body`);
    console.log(body);
    setMessage(<Spinner />);
    setMessage(<SendEmail task="resetPassword" body={body} />);
    setStatus("sent");
  };
  if (validEmail) {
    content = (
      <>
        {status === "pending" ? (
          <VStack w={"100%"}>
            <p>{message}</p>
            <Button
              colorScheme="blue"
              onClick={() => {
                send();
              }}
              className="buttonBigTxt"
            >
              <VStack>
                <div>ZRESETUJ</div>
                <div>HASŁO</div>
              </VStack>
            </Button>
          </VStack>
        ) : (
          message
        )}
      </>
    );
  }
  return (
    <>
      {mode === "modal" && form} {content}
    </>
  );
};
export default ResetPasswordComponent;
