import { createSlice } from "@reduxjs/toolkit";
import { createReducer } from "@reduxjs/toolkit";

export const preferencesSlice = createSlice({
  name: "preferences",
  initialState: {
    garageId: "",
    itemTypes: [],
  },
  reducers: {
    setPreferences: (state, action) => {
      if (action.payload.target === "ItemTypes") {
        return {
          ...state,
          itemTypes: action.payload.value,
        };
      } else return { ...state };
    },
  },
});

export const { setPreferences } = preferencesSlice.actions;
export default preferencesSlice.reducer;
