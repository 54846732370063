import { Link } from "react-router-dom";
import Garage from "./Garage";
import { useGetGaragesQuery } from "./garagesApiSlice";
import { HStack, Progress, VStack } from "@chakra-ui/react";
import Map from "../../components/Map";
import AppointmentModal from "../calendar/public/AppointmentModal";
import LoginModal from "../auth/LoginModal";
import { useDisclosure } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCompress } from "@fortawesome/free-solid-svg-icons";
import { faExpand } from "@fortawesome/free-solid-svg-icons";

const GaragesList = () => {
  const { getButtonProps, getDisclosureProps, isOpen } = useDisclosure();

  const [hidden, setHidden] = useState(false);
  const [listOfGarages, setListOfGarages] = useState([]);
  const [content, setContent] = useState(
    <VStack width={"100%"} height={"4px"} justifyContent={"center"}>
      <Progress
        width={"100%"}
        height={"5px"}
        isIndeterminate
        colorScheme="blue"
      />
    </VStack>
  );
  const {
    data: garages,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetGaragesQuery("garagesList", {
    pollingInterval: 60000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  // if (isError) {
  //   content = <p className="errmsg">{error?.data?.message}</p>;
  // }

  useEffect(() => {
    if (garages) {
      const { ids, entities } = garages;
      setListOfGarages(
        ids?.length &&
          ids.map((garageId) => (
            <Garage width="100%" key={garageId} garage={entities[garageId]} />
          ))
      );
    }
  }, [garages]);
  useEffect(() => {
    if (listOfGarages.length > 0) {
      setContent(
        <>
          <AppointmentModal key="appointmentModal" />
          <HStack className="garagesListPage">
            <motion.div
              {...getDisclosureProps()}
              hidden={hidden}
              initial={false}
              onAnimationStart={() => setHidden(false)}
              onAnimationComplete={() => setHidden(false)}
              animate={{ left: !isOpen ? "0px" : "-45%" }}
              style={{
                background: "transparent",
                overflow: "hidden",
                whiteSpace: "nowrap",
                position: "fixed",
                top: "84px",
                left: "0px",
                height: "100%",
                zIndex: "1",
              }}
            >
              {/* <VStack className="garagesListWrap"> */}
              <HStack alignItems={"flex-start"}>
                <div className="garagesList">{listOfGarages}</div>
                <button
                  className="triggerButton primaryBackground"
                  {...getButtonProps()}
                >
                  {isOpen ? (
                    <FontAwesomeIcon icon={faExpand} />
                  ) : (
                    <FontAwesomeIcon icon={faCompress} />
                  )}
                </button>
                {/* </VStack> */}
              </HStack>
            </motion.div>
            <div className="mapa">
              <Map />
            </div>
            <footer></footer>
          </HStack>
        </>
      );
    }
  }, [listOfGarages]);
  return content;
};

export default GaragesList;
