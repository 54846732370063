import { Switch, VStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useUpdateUsersPermissionsMutation } from "../users/usersApiSlice";
import useAuth from "../../hooks/useAuth";
const PermissionsComponent = ({ garage, isstaff, uid }) => {
  const UserInfo = useAuth();
  console.log(`mfn ${garage}`);
  const [updating, setUpdating] = useState(false);
  const [processChecked, setProcessChecked] = useState(
    garage.permissions.processing,
  );
  const [mailingChecked, setMailingChecked] = useState(
    garage.permissions.mailing,
  );
  const [smsChecked, setSmsChecked] = useState(garage.permissions.sms);
  const [
    updatePermissions,
    { data: perm, isSuccess, isLoading, isError, error },
  ] = useUpdateUsersPermissionsMutation();
  useEffect(() => {
    if (updating) {
      update();
    }
  }, [updating]);
  const update = () => {
    updatePermissions({
      uid: uid,
      gid: garage._id,
      staffId: UserInfo._id,
      permissions: {
        processing: processChecked,
        sms: smsChecked,
        mailing: mailingChecked,
      },
    });
    setUpdating(false);
  };
  const mailingHandler = async (e) => {
    setMailingChecked(!mailingChecked);
    setUpdating(true);
  };
  const processHandler = () => {
    setProcessChecked(!processChecked);
    setUpdating(true);
  };
  const smsHandler = () => {
    setSmsChecked(!smsChecked);
    setUpdating(true);
  };

  return (
    <>
      <VStack
        padding={10}
        alignItems={"left"}
        borderBottom={"1px solid #efefef"}
      >
        {!isstaff && <p>{garage._id}</p>}
        <p>
          przetwarzanie danych:{" "}
          <Switch
            colorScheme={"green"}
            size={"sm"}
            isDisabled={isstaff}
            onChange={processHandler}
            isChecked={processChecked}
          />
        </p>
        <p>
          powiadomienia mailowe:{" "}
          <Switch
            colorScheme={"green"}
            size={"sm"}
            isDisabled={isstaff}
            onChange={mailingHandler}
            isChecked={mailingChecked}
          />
        </p>

        <p>
          powiadomienia sms:{" "}
          <Switch
            colorScheme={"green"}
            size={"sm"}
            isDisabled={isstaff}
            onChange={smsHandler}
            isChecked={smsChecked}
          />
        </p>
      </VStack>
    </>
  );
};
export default PermissionsComponent;
