import { Box, Button, HStack } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import PlanedList from "./PlanedList";
import SmsComponent from "./SmsComponent";
import { useSendSmsMutation } from "./smsApiSlice";
import NumbersList from "./NumbersList";
import { useDispatch, useSelector } from "react-redux";
import { setServiceModalMode } from "../services/serviceModalModeSlice";
import { useEffect } from "react";
import { setNrs } from "./selectedNrsSlice";

const SmsView = () => {
  const selectedGarage = useSelector((state) => state.selectedGarage.garage);
  const dispatch = useDispatch();
  const { garageId } = useParams();
  const UserInfo = useAuth();
  // console.log(`UserInfo`);
  // console.log(UserInfo);
  // console.log(`UserInfo`);
  const [sendSms, { sms, isLoading }] = useSendSmsMutation();
  const nrs = ["1", "2"];
  const send = () => {
    sendSms({ gid: garageId, uid: UserInfo._id, nrs });
  };
  useEffect(() => {
    dispatch(setNrs([]));
    dispatch(setServiceModalMode(""));
  }, []);
  return (
    <>
      <HStack
        border={"none"}
        h={"100%"}
        align={"start"}
        justifyContent={"space-between"}
        verticalAlign={"top"}
        w={"100%"}
        spacing={4}
      >
        <Box h={"100%"} w={"100%"}>
          {UserInfo._id && <PlanedList gid={garageId} uid={UserInfo._id} />}
        </Box>
        <Box w={"100%"}>
          <NumbersList />
        </Box>
        <Box h={"100%"} w={"100%"}>
          {selectedGarage.sms === true ? (
            <SmsComponent uid={UserInfo._id} />
          ) : (
            "nie posiadasz zdefiniowanego providera..."
          )}
        </Box>
      </HStack>
    </>
  );
};

export default SmsView;
