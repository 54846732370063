import { useParams } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import PermissionsComponent from "./PermissionsComponent";
import { useEffect, useState } from "react";
import { Box } from "@chakra-ui/react";

const GaragesTable = ({ user, isstaff }) => {
  const { garageId } = useParams();
  const UserInfo = useAuth();
  let content = "";
  content = user.garages?.map((g) => {
    if (isstaff) {
      if (g.garage === garageId) {
        return (
          <PermissionsComponent
            uid={user._id}
            isstaff={isstaff}
            key={g.id}
            garage={g}
          />
        );
      }
    } else {
      return (
        <PermissionsComponent
          uid={user._id}
          isstaff={isstaff}
          key={g.id}
          garage={g}
        />
      );
    }
  });
  return (
    <>
      <Box
        maxHeight={"200px"}
        overflowY={"auto"}
        marginTop={"10px"}
        border={"1px solid #efefef"}
        borderRadius={5}
        className="small"
      >
        {content}
      </Box>
    </>
  );
};
export default GaragesTable;
